import axios from "axios";
import { decode } from "jsonwebtoken";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import packageJson from "../../package.json";
import authReducer, { STATUSES } from "./authReducer";

export const ROLES = {
  seller: "seller",
  customer: "customer",
};

const ApiContext = React.createContext();

const STORAGE_KEYS = {
  token: "auth:token",
  refreshToken: "auth:refreshToken",
};

let http;

function ApiProvider({ config, children }) {
  const [state, dispatch] = useReducer(authReducer, {
    config,
    status: "pending",
    userId: null,
  });
  const [sessionId, setSessionId] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [sessionPin, setSessionPin] = useState(null);
  const [scopes, setScopes] = useState([]);

  const apiBaseUrl = config.apiBaseUrl;

  if (!http) {
    http = axios.create({
      baseURL: apiBaseUrl,
      timeout: 50000,
      headers: {
        "X-Client-Name": packageJson.name,
        "X-Client-Version": packageJson.version,
      },
    });

    http.interceptors.request.use((request) => {
      const token = sessionStorage.getItem(STORAGE_KEYS.token);

      if (token) {
        request.headers["Authorization"] = `jwt ${token}`;
      }

      return request;
    });

    http.interceptors.response.use(undefined, async (error) => {
      const response = error.response;
      if (
        response &&
        response.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest
      ) {
        if (!sessionStorage.getItem(STORAGE_KEYS.refreshToken)) {
          error.config.__isRetryRequest = true;
          return Promise.reject(error);
        }
        try {
          const result = await fetch(apiBaseUrl + "/auth/jwt", {
            method: "POST",
            cache: "no-cache",
            headers: {
              "Content-type": "application/json",
            },
            redirect: "follow",
            body: JSON.stringify({
              refreshToken: sessionStorage.getItem(STORAGE_KEYS.refreshToken),
            }),
          });
          if (result.status === 200 || result.status === 201) {
            const { token, refreshToken } = await result.json();
            setTokens({ token, refreshToken });
          } else {
            await clearTokens();
          }
        } catch (authError) {
          error.config.__isRetryRequest = true;
          await clearTokens();
          return Promise.reject(authError);
        }

        // retry the original request
        error.config.__isRetryRequest = true;
        return http(error.config);
      }
      return Promise.reject(error);
    });
  }

  const setTokens = ({ token, refreshToken }) => {
    setScopes(decode(token).scopes);
    sessionStorage.setItem(STORAGE_KEYS.token, token);
    sessionStorage.setItem(STORAGE_KEYS.refreshToken, refreshToken);
  };

  const verifyTokens = async () => {
    const token = sessionStorage.getItem(STORAGE_KEYS.token);
    const refreshToken = sessionStorage.getItem(STORAGE_KEYS.refreshToken);
    if (!token || !refreshToken) {
      dispatch({ type: "rejected", action: {} });
      return;
    }

    dispatch({ type: "pending", action: {} });
    setScopes(decode(token).scopes);
    try {
      const response = await http(apiBaseUrl + `/auth/me`);
      if (response.data && response.data.data.id) {
        dispatch({
          type: "verified",
          action: { userId: response.data.data.id },
        });
        return;
      }
    } catch (error) {
      console.log({ e: error });
    }
    dispatch({ type: "rejected" });
  };

  const clearTokens = async () => {
    sessionStorage.removeItem(STORAGE_KEYS.token);
    sessionStorage.removeItem(STORAGE_KEYS.refreshToken);
  };

  useEffect(() => {
    verifyTokens();
  }, []);

  return (
    <ApiContext.Provider
      value={{
        state,
        isPaused,
        setIsPaused,
        setSessionPin,
        sessionPin,
        setSessionData,
        setSessionId,
        sessionId,
        sessionData,
        clearTokens,
        setTokens,
        scopes,
        dispatch,
        apiBaseUrl,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}

const useAuth = () => {
  const { apiBaseUrl, setTokens, clearTokens, state, dispatch } =
    useContext(ApiContext);

  const isPending = state.tokenStatus === STATUSES.PENDING;
  const isError = state.tokenStatus === STATUSES.ERROR;
  const isSuccess = state.tokenStatus === STATUSES.SUCCESS;
  const isVerified = state.tokenStatus === STATUSES.VERIFIED;
  const isAuthenticated = isVerified;

  const login = async (token) => {
    const result = await fetch(apiBaseUrl + `/auth/token`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({
        token: token,
        clientId: process.env.NEXT_PUBLIC_CLIENT_ID,
      }),
    });

    if (!result.ok) {
      return { success: false };
    }

    const res = await result.json();

    return { success: true, res: res };
  };

  const logout = async () => {
    clearTokens();
    dispatch({ type: "logout" });
  };

  const authenticate = async ({ crossToken }) => {
    const result = await fetch(apiBaseUrl + "/auth/crosstoken/" + crossToken, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
    });

    const { token, refreshToken } = await result.json();

    if (token && refreshToken) {
      dispatch({
        type: "crossToken-confirmed",
        action: { token, refreshToken },
      });
      setTokens({ refreshToken, token });

      // Check /me for userId
      const me = await fetch(apiBaseUrl + `/auth/me`, {
        headers: {
          Authorization: "jwt " + token,
        },
      });
      const res = await me.json();
      if (res.data && res.data.id) {
        dispatch({ type: "verified", action: { userId: res.data.id } });
        return { success: true };
      }
    }
    dispatch({ type: "rejected" });
    return { success: false };
  };

  const loginjwt = async (loginToken) => {
    const result = await fetch(apiBaseUrl + `/auth/token`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({
        token: loginToken,
        clientId: process.env.NEXT_PUBLIC_CLIENT_ID,
      }),
    });

    const { token, refreshToken } = await result.json();

    if (token && refreshToken) {
      dispatch({ type: "otp-confirmed", action: { token, refreshToken } });
      setTokens({ refreshToken, token });

      // Check /me for userId
      const me = await fetch(apiBaseUrl + `/auth/me`, {
        headers: {
          Authorization: "jwt " + token,
        },
      });
      const res = await me.json();
      if (res.data && res.data.id) {
        dispatch({ type: "verified", action: { userId: res.data.id } });
        return { success: true };
      }
    }
    dispatch({ type: "rejected" });
    return { success: false };
  };

  const forgotPassword = async ({ username }) => {
    const result = await fetch(apiBaseUrl + `/auth/reset`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({ username }),
    });

    if (!result.ok) {
      return { success: false };
    }

    const res = await result.json();

    return { success: !!res.success };
  };

  const resetPassword = async ({ username, password, otp }) => {
    const result = await fetch(apiBaseUrl + `/auth/reset`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({ username, password, otp }),
    });

    if (!result.ok) {
      return { success: false };
    }
    const res = await result.json();

    return { success: !!res.success };
  };

  return {
    ...state,
    isPending,
    isError,
    login,
    logout,
    isSuccess,
    forgotPassword,
    resetPassword,
    authenticate,
    isAuthenticated,
    loginjwt,
  };
};

const useUser = () => {
  const { apiBaseUrl } = useContext(ApiContext);
  const { isAuthenticated, userId } = useAuth();
  const [user, setUser] = useState(null);

  const userData = async ({ userId }) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}`,
      method: "GET",
    });
    return response.data.data;
    // { attributes, relationships, included }
  };

  const getUserCourses = async (userId) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/courses`,
      method: "GET",
    });
    return response.data;
  };

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      return;
    }
    const f = async () => {
      const data = await userData({ userId });
      setUser({
        name: `${data.attributes.firstName} ${data.attributes.lastName}`,
        firstName: data.attributes.firstName,
        lastName: data.attributes.lastName,
        phone: data?.attributes?.meta?.phone,
      });
    };
    f();
  }, [isAuthenticated, userId]);

  return { userId, user, getUserCourses };
};

const useLMS = () => {
  const { apiBaseUrl } = useContext(ApiContext);
  const { isAuthenticated, userId } = useAuth();
  const [courses, setCourses] = useState([]);

  const courseDetails = async () => {
    const response = await http({
      url: `${apiBaseUrl}/courses`,
      method: "GET",
    });
    return response.data;
    // { attributes, relationships, included }
  };

  const getAssetUrl = async (id) => {
    const response = await http({
      url: `${apiBaseUrl}/assets/${id}`,
      method: "GET",
    });
    return response.data;
  };

  const createAttempt = async (userId, courseId) => {
    try {
      const response = await http({
        url: `${apiBaseUrl}/attempts/`,
        method: "POST",
        data: {
          data: {
            userId,
            courseId,
          },
        },
      });
      if (response.data.data.attributes.courseId != undefined)
        delete response.data.data.attributes.courseId;
      if (response.data.data.attributes.userId != undefined)
        delete response.data.data.attributes.userId;
      if (response.data.data.attributes.createdAt != undefined)
        delete response.data.data.attributes.createdAt;
      if (response.data.data.attributes.updatedAt != undefined)
        delete response.data.data.attributes.updatedAt;
      if (response.data.data.attributes.status === "NOT ATTEMPTED")
        response.data.data.attributes.status = "INCOMPLETE";
      return response.data;
    } catch (error) {
      console.error({ error });
    }
  };
  const getAttempt = async (attemptId) => {
    try {
      const response = await http({
        url: `${apiBaseUrl}/attempts/${attemptId}`,
        method: "GET",
      });
      // removing data we are not supposed to change clientside
      if (response.data.data.attributes.courseId != undefined)
        delete response.data.data.attributes.courseId;
      if (response.data.data.attributes.userId != undefined)
        delete response.data.data.attributes.userId;
      if (response.data.data.attributes.createdAt != undefined)
        delete response.data.data.attributes.createdAt;
      if (response.data.data.attributes.updatedAt != undefined)
        delete response.data.data.attributes.updatedAt;

      return response.data;
    } catch (error) {
      console.error({ error });
    }
  };
  const updateAttempt = async (attemptId, attributes) => {
    if (attemptId == undefined || attributes == undefined) return;
    try {
      const response = await http({
        url: `${apiBaseUrl}/attempts/${attemptId}`,
        method: "PATCH",
        data: {
          data: {
            id: attemptId,
            type: "attempts",
            attributes: attributes,
          },
        },
      });
      return response.data;
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      return;
    }
    const f = async () => {
      const courseDet = await courseDetails();
      if (courseDet?.data?.length > 0) {
        for (const course of courseDet.data) {
          if (course.relationships?.versionOf) {
            let c = courseDet.data.find(
              (c) => c.id == course.relationships?.versionOf?.id
            );
            course.order = c.attributes?.meta?.order;
          }
        }
        courseDet.data.sort((a, b) => {
          // main versions
          if (a.relationships?.versions?.length > 0) {
            a.order = a.attributes?.meta?.order;
          }
          if (b.relationships?.versions?.length > 0) {
            b.order = b.attributes?.meta?.order;
          }
          return Number(a.order) - Number(b.order);
        });
      }
      setCourses(courseDet);
    };
    f();
  }, [isAuthenticated, userId]);

  return { courses, createAttempt, getAttempt, updateAttempt, getAssetUrl };
};

export { ApiProvider, useAuth, useUser, useLMS };

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
